import axios from 'axios';

export const getProductsByList = async (listId)=>{
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_URL_API}/products/list/${listId}`)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const updateProduct = (data)=>{
  const {idProduct, ...params} = data;
  return new Promise((resolve, reject) => {
    axios.patch(`${process.env.REACT_APP_URL_API}/products/${idProduct}`,params)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const getReferenceProducts = async ()=>{
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_URL_API}/reference-products`)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const createNewProduct = async (data)=>{
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_URL_API}/products`,data)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const createNewProductWithList = async (data)=>{
  const {listId, ...params} = data;
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_URL_API}/products/list/${listId}`,params)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const createNewProductWithPantry = async (data)=>{
  const {pantryId, ...params} = data;
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_URL_API}/products/pantry/${pantryId}`,params)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const getAllUserProducts = async ()=>{
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_URL_API}/products/user`)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}