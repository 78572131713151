import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { NavbarComponent } from "../components/Navbar";
import { UserContextProvider } from "../context/UserContext";
import { ProtectedRoute } from "../hooks/protectedRoute";
import { Home } from "pages/Home";
import { Lists } from "pages/Lists";
import { Shopping } from "pages/Shopping";
import { Login } from "pages/Login";
import { Warehouses } from "pages/Warehouses";
import { Pantries } from "pages/Pantries";
import { Signup } from "pages/Signup";
import { Users } from "pages/User";

const AppRouter = () => {
  return (
    <UserContextProvider>
      <BrowserRouter basename={"/"}>
        <NavbarComponent />
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path="/" element={<Home />} />
            <Route path="/almacenes" element={<Warehouses />} />
            <Route path="/listas" element={<Lists />} />
            <Route path="/despensas" element={<Pantries />} />
            <Route path="/usuario" element={<Users />} />
            <Route path="/compras/:listId" element={<Shopping />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/registro" element={<Signup />} />
          <Route
            path="*"
            element={
              <main style={{ padding: "1rem" }}>
                <p>Ruta no valida</p>
              </main>
            }
          />
        </Routes>
      </BrowserRouter>
    </UserContextProvider>
  );
};

export default AppRouter;
