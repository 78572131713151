import React from "react";
import { EditUser } from "./EditUser";
import { UserLanguage } from "./UserLanguage";
import { UserPlan } from "./UserPlan";
import { UserTheme } from "./UserTheme";

export const Users = () => {
  return (
    <>
      <div className="grid p-3">
        <div className="col-12 px-4 pt-5">
          <h2>Configuración</h2>
        </div>
      </div>
      <div className="grid p-3">
        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 p-4">
          <EditUser />
        </div>
        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 p-4">
          <UserTheme />
          <UserLanguage />
        </div>
        <div className="col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12 p-4">
          <UserPlan />
        </div>
      </div>
    </>
  );
};
