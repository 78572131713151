import axios from 'axios';

export const getListsUsers = async ()=>{
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_URL_API}/lists-users`)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const createNewList = async (data)=>{
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_URL_API}/lists`,data)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const updateList = (data)=>{
  const {listId, ...params} = data;
  return new Promise((resolve, reject) => {
    axios.patch(`${process.env.REACT_APP_URL_API}/lists/${listId}`,params)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const createListsUsers = async (data)=>{
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_URL_API}/lists-users`,data)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const updateListsUsers = (data)=>{
  const {idListUser, ...params} = data;
  return new Promise((resolve, reject) => {
    axios.patch(`${process.env.REACT_APP_URL_API}/lists-users/${idListUser}`,params)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const updateListsUsersByUser = (data)=>{
  const {idListUser, ...params} = data;
  return new Promise((resolve, reject) => {
    axios.patch(`${process.env.REACT_APP_URL_API}/lists-users/user/${idListUser}`,params)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const getListsUsersByListId = async (listId)=>{
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_URL_API}/lists-users/list/${listId}`)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}