import React, { useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Menubar } from "primereact/menubar";
import { useUser } from "../hooks/useUser";

import { Menu } from "primereact/menu";
import { Button } from "primereact/button";

export const NavbarComponent = () => {
  const location = useLocation();
  const [menuOption, setMenuOption] = useState(location.pathname.slice(1));
  const { isLogged, logout, user } = useUser();
  const navigate = useNavigate();
  const menu = useRef(null);

  const items = [
    {
      label: "Inicio",
      icon: "pi pi-fw pi-home",
      className:
        menuOption === "home" || menuOption === "" ? "p-menuitem-active" : null,
      command: () => {
        navigate("/");
        setMenuOption("home");
      },
    },
    {
      label: "Listas",
      className: menuOption === "listas" ? "p-menuitem-active" : null,
      command: () => {
        navigate("/listas");
        setMenuOption("listas");
      },
    },
    {
      label: "Despensas",
      className: menuOption === "despensas" ? "p-menuitem-active" : null,
      command: () => {
        navigate("/despensas");
        setMenuOption("despensas");
      },
    },
  ];

  const items2 = [
    {
      label: "Configuración",
      icon: "pi pi-cog",
      command: () => {
        navigate("/usuario");
        setMenuOption("usuario");
      },
    },
    {
      label: "Cerrar Sesión",
      icon: "pi pi-sign-out",
      command: () => {
        setMenuOption("home");
        logout();
      },
    },
  ];

  const renderUserMenu = () => {
    return (
      <>
        <Menu model={items2} popup ref={menu} id="popup_menu" />
        <Button
          label={`${user?.name} ${user?.lastName}`}
          icon="pi pi-user"
          className="p-button-rounded mr-3"
          onClick={(event) => menu.current.toggle(event)}
          aria-controls="popup_menu"
          aria-haspopup
        />
      </>
    );
  };

  const start = (
    <img
      alt="logo"
      src="/img/logo_128.png"
      onError={(e) =>
        (e.target.src =
          "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
      }
      height="40"
      className="mr-2"
    ></img>
  );
  const end = isLogged ? (
    renderUserMenu
  ) : (
    <div onClick={() => navigate("/login")}>Ingresar</div>
  );

  return isLogged && <Menubar model={items} start={start} end={end} />;
};
