import axios from 'axios';

export const getPantriesUsers = async ()=>{
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_URL_API}/pantries-users`)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const getProductsPantriesByPantry = async (pantryId)=>{
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_URL_API}/products-pantries/pantry/${pantryId}`)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const getProductsPantriesByUser = async ()=>{
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_URL_API}/products-pantries/user`)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const getProductsPantriesCloseToExpiration = async ()=>{
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_URL_API}/products-pantries/close-to-expiration`)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const updateProductsPantries = (data)=>{
  const {idProductsPantries, ...params} = data;
  return new Promise((resolve, reject) => {
    axios.patch(`${process.env.REACT_APP_URL_API}/products-pantries/${idProductsPantries}`,params)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const bulkCreateProductsPantries = async (data)=>{
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_URL_API}/products-pantries/bulk`,data)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const createNewProductsPantries = async (data)=>{
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_URL_API}/products-pantries`,data)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}
