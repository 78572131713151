import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import { InputNumber } from "primereact/inputnumber";
import { AutoComplete } from "primereact/autocomplete";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import {
  createNewProductWithPantry,
  getAllUserProducts,
  getReferenceProducts,
  updateProduct,
} from "../../services/products";
import { getUnits } from "../../services/units";
import { createNewProductsPantries, getProductsPantriesByPantry, updateProductsPantries } from "../../services/productsPantries";
import { confirmDialog } from "primereact/confirmdialog";
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";

export const PantryDetail = ({ pantryId, toast }) => {
  const [products, setProducts] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [newProduct, setNewProduct] = useState(null);
  const [newProductState, setNewProductState] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [groupedProductsList, setGroupedProductsList] = useState([]);
  const [unitsList, setUnitsList] = useState([]);

  const currentDate = new Date();

  addLocale("es", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
    today: "Hoy",
    clear: "Borrar",
  });

  useEffect(
    (
      getReferenceProductsListFunc = getReferenceProductsList,
      getUnitsListFunc = getUnitsList
    ) => {
      getReferenceProductsListFunc();
      getUnitsListFunc();
    },
    []
  );

  useEffect(
    (getProductsFunc = getProducts) => {
      getProductsFunc();
    },
    [pantryId]
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm();

  const getProducts = async () => {
    if (pantryId) {
      const lists = await getProductsPantriesByPantry(pantryId);
      setProducts(lists.data);
    }
  };

  const getUnitsList = async () => {
    const list = await getUnits(pantryId);
    setUnitsList(list.data);
  };

  const getUserProducts = async (referenceProductsList2) => {
    const userProductsRes = await getAllUserProducts();
    let userProducts = userProductsRes.data
    const newUserProductsList = [
      { label: "Mis Productos", code: "PU", items: userProducts },
      {
        label: "Productos Referencia",
        code: "PR",
        items: referenceProductsList2,
      },
    ];
    setGroupedProductsList(newUserProductsList);
  };

  const getReferenceProductsList = async () => {
    const list = await getReferenceProducts();
    getUserProducts(list.data);
  };

  const validateProductExists = () => {
    if (newProduct.idProduct) {
      let productExist = products.find(
        (productItemList) =>
          productItemList.product.idProduct === newProduct.idProduct
      );
      if (productExist) {
        return false;
      }
    }
    return true;
  };

  const createProduct = () => {
    if (!newProduct) {
      setNewProductState(true);
    } else {
      let valid = true;
      var data;
      if (typeof newProduct === "object") {
        let { createdAt, updatedAt, active, ...rest } = newProduct;
        data = rest;
        valid = validateProductExists();
      } else {
        data = { name: newProduct };
      }
      data.pantryId = pantryId;
      if (valid && !newProduct.idProduct) {
        createNewProductWithPantry(data)
          .then((newProductRes) => {
            const newProductsLists = [...products, newProductRes.data];
            setProducts(newProductsLists);
            setNewProduct(null);
          })
          .catch((err) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Hubo un error al guardar el producto",
              sticky: true,
            });
          });
      }
      if (valid && newProduct.idProduct) {
        confirmCreateProduct(newProduct.idProduct)
      }
      if (!valid && newProduct.idProduct) {
        confirmDialog({
          message: `El producto ${newProduct.name} ya se encuentra en la despensa, puedes incrementar la cantidad del existente o si lo deseas tambien puedes agregarlo nuevamente en caso de que la fecha de vencimiento sea diferente. Deseas agregarlo nuevamente?`,
          header: "Agregar producto ya existente en la despensa",
          icon: "pi pi-info-circle",
          acceptClassName: "p-button-info",
          accept: () => {
            confirmCreateProduct(newProduct.idProduct)
          },
          reject: () => {
            setNewProduct(null)
          },
          acceptLabel: "Sí, agregar el producto nuevamente",
          rejectLabel: "No, editaré el existente"
        });
      }
    }
  };

  const confirmCreateProduct = (idProduct) => {
    createNewProductsPantries({
      pantryId: pantryId,
      idProduct: idProduct,
    })
      .then((newProductRes) => {
        const newProductsList = [...products, newProductRes.data];
        setProducts(newProductsList);
        setNewProduct(null);
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Hubo un error al guardar el producto en la despensa",
          sticky: true,
        });
      });
  }

  const editProduct = (data) => {
    updateProduct(data)
      .then((updatedProduct) => {
        getProducts();
        toast.current.show({
          severity: "success",
          summary: "Editado",
          detail: `El producto se editó correctamente`,
        });
      })
      .catch((err) => {
        console.error(err);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Hubo un error al editar el producto",
          sticky: true,
        });
      });
  };
  
  const editProductsPantries = (data) => {
    updateProductsPantries(data)
      .then((updatedProduct) => {
        if (updatedProduct.data.active === false) {
          const newProductsPantry = products.filter(product => product.idProductsPantries !==updatedProduct.data.idProductsPantries)
          setProducts(newProductsPantry)
          toast.current.show({
            severity: "success",
            summary: "Editado",
            detail: `El producto fue eliminado`,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Hubo un error al editar la cantidad",
          sticky: true,
        });
      });
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <Avatar
        image={
          rowData.image
            ? rowData.image
            : `/img/products_64.png`
        }
        className="mr-2"
        size="large"
        shape="circle"
        alt={rowData.image}
      />
    );
  };

  const quantityTemplate = (rowData) => {
    return (
      <InputNumber
        className="products-quantity"
        inputId="horizontal"
        value={rowData.quantity}
        onValueChange={(e) => {
          editProductsPantries({
            idProductsPantries: rowData.idProductsPantries,
            quantity: e.value,
          });
        }}
        showButtons
        step={1}
        min={0}
        max={30}
      />
    );
  };

  const expirationTemplate = (rowData) => {
    return (
      <Calendar
        id="icon"
        inputStyle={{ width: "150px" }}
        value={rowData.expiration ? new Date(rowData.expiration) : '' }
        onChange={(e) => {
          editProductsPantries({
            idProductsPantries: rowData.idProductsPantries,
            option: "expiration",
            expiration: e.value,
          });
        }}
        showIcon
        minDate={currentDate}
        showButtonBar
        dateFormat="dd/mm/yy"
        locale="es"
        monthNavigator
        yearNavigator
        monthNavigatorTemplate={monthNavigatorTemplate}
        yearNavigatorTemplate={yearNavigatorTemplate}
        yearRange={`${currentDate.getFullYear()}:${
          currentDate.getFullYear() + 10
        }`}
      />
    );
  };

  const monthNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  };

  const yearNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  };

  const actionsTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-button-rounded p-button-danger m-1"
        onClick={() => {
          confirmDialog({
            message: `Estas seguro de querer eliminar el producto ${rowData.product.name} de la despensa ${rowData.pantry.name}?`,
            header: "Eliminar producto de despensa",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept: () => {editProductsPantries({
              idProductsPantries: rowData.idProductsPantries,
              active: false,
            })},
            acceptLabel: "Continuar",
          });
        }}
      />
    );
  };

  const onSubmit = (data) => {
    var params = {};
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        if (element) {
          params[key] = element;
        }
      }
    }
    editProduct(params);
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const rowExpansionTemplate = (data) => {
    var variables = [
      "idProduct",
      "name",
      "categoryId",
      "expiration",
      "image",
      "price",
      "reference",
      "unitId",
    ];
    variables.forEach((element) => {
      if (data.product[element]) {
        switch (element) {
          case "price":
            setValue(element, Number(data.product[element]));
            break;
          case "expiration":
            setValue(element, new Date(data.product[element]));
            break;
          default:
            setValue(element, data.product[element]);
            break;
        }
      }
    });

    return (
      <div className="grid">
        {/* <h5>Orders for {data.name}</h5> */}
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <div className="grid">
            <div className="col-4">
              <div className="field">
                <label
                  htmlFor="name"
                  className={classNames({ "p-error": errors.name })}
                >
                  Nombre
                </label>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: "Name is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                {getFormErrorMessage("name")}
              </div>
            </div>
            <div className="col-4">
              <div className="field">
                <label
                  htmlFor="reference"
                  className={classNames({ "p-error": errors.reference })}
                >
                  Codigo
                </label>
                <Controller
                  name="reference"
                  control={control}
                  rules={{
                    maxLength: { value: 20, message: "Máximo 20 caracteres" },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                {getFormErrorMessage("reference")}
              </div>
            </div>
            <div className="col-4">
              <div className="field">
                <label htmlFor="price">Precio</label>
                <Controller
                  name="price"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                      onChange={(e) => {
                        setValue(field.name, e.value);
                      }}
                      mode="currency"
                      currency="COP"
                      locale="es-CO"
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="field">
                <label htmlFor="unitId">Unidad de medida</label>
                <Controller
                  name="unitId"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={unitsList}
                      optionLabel="name"
                      optionValue="idUnit"
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-4 mt-4">
              <Button type="submit" label="Guardar" className="mt-2" />
            </div>
          </div>
        </form>
      </div>
    );
  };

  const searchProduct = (event) => {
    let query = event.query;
    let _filteredProducts = [];
    for (let productItem of groupedProductsList) {
      let filteredItems = productItem.items.filter(
        (item) => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
      if (filteredItems && filteredItems.length) {
        _filteredProducts.push({ ...productItem, ...{ items: filteredItems } });
      }
    }
    setFilteredProducts(_filteredProducts);
  };

  const groupedItemTemplate = (item) => {
    return (
      <div className="d-flex align-items-center">
        <div>{item.label}</div>
      </div>
    );
  };

  const autocompleteItemTemplate = (item) => {
    return (
      <div className="d-flex align-items-center">
        <Avatar
          image={
            item.image
              ? item.image
              : `https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png`
          }
          className="mr-2"
          shape="circle"
          alt={item.name}
        />
        <div>{item.name}</div>
      </div>
    );
  };

  const header = (
    <div className="table-header d-flex align-items-center justify-content-space-between grid">
      <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-4">
        {`Hay ${products ? products.length : 0} productos en la despensa.`}
      </div>
      <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-4 d-flex align-items-end">
        <div className="col-10">
          <label htmlFor="name" className="mr-2 float-right">
            Agregar
          </label>
          <AutoComplete
            name="name"
            value={newProduct}
            suggestions={filteredProducts}
            completeMethod={searchProduct}
            placeholder="Nombre Producto"
            className={
              newProductState ? "p-invalid .float-right" : ".float-right"
            }
            field="name"
            optionGroupLabel="label"
            optionGroupChildren="items"
            optionGroupTemplate={groupedItemTemplate}
            itemTemplate={autocompleteItemTemplate}
            onChange={(e) => {
              setNewProduct(e.value);
              setNewProductState(false);
            }}
          />
        </div>
        <div className="col-2">
          <Button
            icon="pi pi-plus"
            className="p-button-rounded p-button-info"
            onClick={() => {
              createProduct();
            }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <DataTable
        value={products}
        header={header}
        responsiveLayout="stack"
        expandedRows={expandedRows}
        stripedRows
        emptyMessage={"No se encontraron productos en la despensa"}
        /* onRowToggle={(e) => {setExpandedRows({[e.data.idProduct]:true})}} */
        onRowExpand={(e) => {
          reset();
          setExpandedRows({ [e.data.idProductsPantries]: true });
        }}
        onRowCollapse={(e) => setExpandedRows({})}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="idProductsPantries"
      >
        <Column expander style={{ width: "5%" }} />
        <Column
          header=""
          style={{ width: "5%" }}
          body={imageBodyTemplate}
        ></Column>
        <Column
          field="product.name"
          header="Productos"
          style={{ width: "80%" }}
        ></Column>
        <Column
          body={expirationTemplate}
          header="Fecha de vencimiento"
        ></Column>
        <Column
          header="Cantidad"
          style={{ width: "10%" }}
          body={quantityTemplate}
        ></Column>
        <Column
          header="Eliminar"
          style={{ width: "5%" }}
          body={actionsTemplate}
        ></Column>
      </DataTable>
    </>
  );
};
