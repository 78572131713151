import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Avatar } from "primereact/avatar";
import { InputNumber } from "primereact/inputnumber";
import { AutoComplete } from "primereact/autocomplete";
import { classNames } from "primereact/utils";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from "primereact/tooltip";
import {
  createNewProductWithList,
  getAllUserProducts,
  getReferenceProducts,
  updateProduct,
} from "../../services/products";
import {
  createNewProductsLists,
  getProductsListsByList,
  updateProductsLists,
} from "../../services/productsLists";
import { getUnits } from "../../services/units";
import { confirmDialog } from "primereact/confirmdialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";

export const ListDetail = ({ listId, toast }) => {
  const [products, setProducts] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [newProduct, setNewProduct] = useState(null);
  const [newProductState, setNewProductState] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [groupedProductsList, setGroupedProductsList] = useState([]);
  const [unitsList, setUnitsList] = useState([]);
  const [filters, setFilters] = useState(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  useEffect(
    (
      getReferenceProductsListFunc = getReferenceProductsList,
      getUnitsListFunc = getUnitsList
    ) => {
      getReferenceProductsListFunc();
      getUnitsListFunc();
      initFilters();
    },
    []
  );

  useEffect(
    (getProductsFunc = getProducts) => {
      getProductsFunc();
    },
    [listId]
  );

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm();

  const clearFilter = () => {
    initFilters();
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      "product.name": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setGlobalFilterValue("");
  };

  const getProducts = async () => {
    if (listId) {
      const lists = await getProductsListsByList(listId);
      setProducts(lists.data);
    }
  };

  const getUnitsList = async () => {
    const list = await getUnits(listId);
    setUnitsList(list.data);
  };

  const getUserProducts = async (referenceProductsList2) => {
    const userProductsRes = await getAllUserProducts();
    let userProducts = userProductsRes.data;
    const newUserProductsList = [
      { label: "Mis Productos", code: "PU", items: userProducts },
      {
        label: "Productos Referencia",
        code: "PR",
        items: referenceProductsList2,
      },
    ];
    setGroupedProductsList(newUserProductsList);
  };

  const getReferenceProductsList = async () => {
    const list = await getReferenceProducts();
    getUserProducts(list.data);
  };

  const validateProductExists = () => {
    if (newProduct.idProduct) {
      let productExist = products.find(
        (productItemList) =>
          productItemList.product.idProduct === newProduct.idProduct
      );
      if (productExist) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail:
            "Este producto ya se encuentra en esta lista, si deseas usa uno de los productos de referencia o crea uno nuevo",
          sticky: true,
        });
        return false;
      }
    }
    return true;
  };

  const createProduct = () => {
    if (!newProduct) {
      setNewProductState(true);
    } else {
      let valid = true;
      var data;
      if (typeof newProduct === "object") {
        let { createdAt, updatedAt, active, ...rest } = newProduct;
        data = rest;
        valid = validateProductExists();
      } else {
        data = { name: newProduct };
      }
      data.listId = listId;
      if (valid && !newProduct.idProduct) {
        createNewProductWithList(data)
          .then((newProductRes) => {
            const newProductsLists = [...products, newProductRes.data];
            setProducts(newProductsLists);
            setNewProduct(null);
          })
          .catch((err) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Hubo un error al guardar el producto",
              sticky: true,
            });
          });
      }
      if (valid && newProduct.idProduct) {
        createNewProductsLists({
          listId: listId,
          idProduct: newProduct.idProduct,
        })
          .then((newProductRes) => {
            const newProductsList = [...products, newProductRes.data];
            setProducts(newProductsList);
            setNewProduct(null);
          })
          .catch((err) => {
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Hubo un error al guardar el producto en la lista",
              sticky: true,
            });
          });
      }
    }
  };

  const editProduct = (data) => {
    updateProduct(data)
      .then((updatedProduct) => {
        getProducts();
        toast.current.show({
          severity: "success",
          summary: "Editado",
          detail: `El producto se editó correctamente`,
        });
      })
      .catch((err) => {
        console.error(err);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Hubo un error al editar el producto",
          sticky: true,
        });
      });
  };

  const editProductsLists = (data) => {
    updateProductsLists(data)
      .then((updatedProduct) => {
        if (updatedProduct.data.active === false) {
          const newProductsList = products.filter(
            (product) =>
              product.idProductsLists !== updatedProduct.data.idProductsLists
          );
          setProducts(newProductsList);
          toast.current.show({
            severity: "success",
            summary: "Editado",
            detail: `El producto fue eliminado`,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Hubo un error al editar la cantidad",
          sticky: true,
        });
      });
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <Avatar
        image={rowData.image ? rowData.image : `/img/products_64.png`}
        className="mr-2"
        size="large"
        shape="circle"
        alt={rowData.image}
      />
    );
  };

  const quantityTemplate = (rowData) => {
    return (
      <InputNumber
        className="products-quantity"
        inputId="horizontal"
        value={rowData.quantity}
        onValueChange={(e) => {
          editProductsLists({
            idProductsLists: rowData.idProductsLists,
            quantity: e.value,
          });
        }}
        showButtons
        step={1}
        min={0}
        max={30}
      />
    );
  };

  const actionsTemplate = (rowData) => {
    return (
      <Button
        icon="pi pi-trash"
        className="p-button-rounded p-button-danger m-1"
        onClick={() => {
          confirmDialog({
            message: `Estas seguro de querer eliminar el producto ${rowData.product.name} de la lista ${rowData.list.name}?`,
            header: "Eliminar producto de lista",
            icon: "pi pi-info-circle",
            acceptClassName: "p-button-danger",
            accept: () => {
              editProductsLists({
                idProductsLists: rowData.idProductsLists,
                active: false,
              });
            },
            acceptLabel: "Continuar",
          });
        }}
      />
    );
  };

  const onSubmit = (data) => {
    var params = {};
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        if (element) {
          params[key] = element;
        }
      }
    }
    editProduct(params);
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  const rowExpansionTemplate = (data) => {
    var variables = [
      "idProduct",
      "name",
      "categoryId",
      "expiration",
      "image",
      "price",
      "reference",
      "unitId",
    ];
    variables.forEach((element) => {
      if (data.product[element]) {
        switch (element) {
          case "price":
            setValue(element, Number(data.product[element]));
            break;
          case "expiration":
            setValue(element, new Date(data.product[element]));
            break;
          default:
            setValue(element, data.product[element]);
            break;
        }
      }
    });

    return (
      <div className="grid">
        {/* <h5>Orders for {data.name}</h5> */}
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <div className="grid">
            <div className="col-4">
              <div className="field">
                <label
                  htmlFor="name"
                  className={classNames({ "p-error": errors.name })}
                >
                  Nombre
                </label>
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: "Name is required." }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      autoFocus
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                {getFormErrorMessage("name")}
              </div>
            </div>
            <div className="col-4">
              <div className="field">
                <label
                  htmlFor="reference"
                  className={classNames({ "p-error": errors.reference })}
                >
                  Codigo
                </label>
                <Controller
                  name="reference"
                  control={control}
                  rules={{
                    maxLength: { value: 20, message: "Máximo 20 caracteres" },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                {getFormErrorMessage("reference")}
              </div>
            </div>
            <div className="col-4">
              <div className="field">
                <label htmlFor="price">Precio</label>
                <Controller
                  name="price"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                      onChange={(e) => {
                        setValue(field.name, e.value);
                      }}
                      mode="currency"
                      currency="COP"
                      locale="es-CO"
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-4">
              <div className="field">
                <label htmlFor="unitId">Unidad de medida</label>
                <Controller
                  name="unitId"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={unitsList}
                      optionLabel="name"
                      optionValue="idUnit"
                    />
                  )}
                />
              </div>
            </div>
            <div className="col-4 mt-4">
              <Button type="submit" label="Guardar" className="mt-2" />
            </div>
          </div>
        </form>
      </div>
    );
  };

  const searchProduct = (event) => {
    let query = event.query;
    let _filteredProducts = [];
    for (let productItem of groupedProductsList) {
      let filteredItems = productItem.items.filter(
        (item) => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
      if (filteredItems && filteredItems.length) {
        _filteredProducts.push({ ...productItem, ...{ items: filteredItems } });
      }
    }
    setFilteredProducts(_filteredProducts);
  };

  const groupedItemTemplate = (item) => {
    return (
      <div className="d-flex align-items-center">
        <div>{item.label}</div>
      </div>
    );
  };

  const autocompleteItemTemplate = (item) => {
    return (
      <div className="d-flex align-items-center">
        <Avatar
          image={item.image ? item.image : `/img/products_64.png`}
          className="mr-2"
          shape="circle"
          alt={item.name}
        />
        <div>{item.name}</div>
      </div>
    );
  };

  const header = (
    <div className="table-header d-flex align-items-center justify-content-space-between grid">
      <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6">
        <span className="mr-2">
          {`Hay ${products ? products.length : 0} productos en la lista.`}
        </span>
        <span
          className={
            globalFilterValue ? "p-input-icon-right" : "p-input-icon-left"
          }
        >
          {globalFilterValue ? (
            <i
              className="pi pi-times"
              style={{ cursor: "pointer" }}
              onClick={clearFilter}
            />
          ) : (
            <i className="pi pi-search" />
          )}
          <InputText
            name="search"
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Buscar producto en lista"
          />
        </span>
      </div>
      <div className="col-12 sm:col-12 md:col-12 lg:col-4 xl:col-4 d-flex align-items-end">
        <Tooltip
          position="left"
          target=".info-create"
          mouseTrack
          mouseTrackLeft={10}
        />
        <i
          className="pi pi-info-circle info-create mr-2 mb-3"
          data-pr-tooltip="Para agregar un producto a la lista ingresa el nombre que desees, puedes elegir una opción de los productos de referencia o simplemente usa el botón de guardado"
        ></i>
        <div className="p-inputgroup">
          <AutoComplete
            name="name"
            value={newProduct}
            suggestions={filteredProducts}
            completeMethod={searchProduct}
            placeholder="Agregar un Producto"
            className={
              newProductState ? "p-invalid .float-right" : ".float-right"
            }
            field="name"
            optionGroupLabel="label"
            optionGroupChildren="items"
            optionGroupTemplate={groupedItemTemplate}
            itemTemplate={autocompleteItemTemplate}
            onChange={(e) => {
              setNewProduct(e.value);
              setNewProductState(false);
            }}
          />
          <Button
            icon="pi pi-plus"
            onClick={() => {
              createProduct();
            }}
          />
        </div>
      </div>
    </div>
  );

  return (
    <>
      <DataTable
        value={products}
        header={header}
        responsiveLayout="stack"
        expandedRows={expandedRows}
        stripedRows
        emptyMessage={"No se encontraron productos en la lista"}
        onRowExpand={(e) => {
          reset();
          setExpandedRows({ [e.data.idProduct]: true });
        }}
        onRowCollapse={(e) => setExpandedRows({})}
        rowExpansionTemplate={rowExpansionTemplate}
        dataKey="idProduct"
        globalFilterFields={["product.name"]}
        filters={filters}
        filterDisplay="Buscar producto"
      >
        <Column expander />
        <Column header="" body={imageBodyTemplate}></Column>
        <Column field="product.name" header="Producto"></Column>
        <Column header="Cantidad" body={quantityTemplate}></Column>
        <Column header="Eliminar" body={actionsTemplate}></Column>
      </DataTable>
    </>
  );
};
