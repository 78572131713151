import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import { ProgressSpinner } from "primereact/progressspinner";
import { Message } from "primereact/message";
import { Divider } from "primereact/divider";
import { Card } from "primereact/card";
import { useUser } from "../hooks/useUser";
import { useNavigate } from "react-router-dom";

export const Login = () => {
  const defaultValues = {
    username: "",
    password: "",
  };

  const { login, isLogged, isLoading, hasLoginError, errorMessage } = useUser();
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ defaultValues });

  useEffect(() => {
    if (isLogged) {
      navigate("/");
    }
  }, [isLogged, navigate]);

  const onSubmit = (data) => {
    login(data);
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  return (
    <div className="grid justify-content-center align-items-center">
      <div className="col-12 sm:col-12 md:col-7 lg:col-7 xl:col-7 p-3 d-mobile-none">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "700px" }}
        >
          <img
            src="/img/front_market_1024.png"
            alt="Imagen aplicación lista de compras"
            style={{ margin: "auto", width: "80%" }}
          />
        </div>
      </div>
      <div className="col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4 p-3">
        <Card>
          <h5 className="text-center">Ingresar</h5>
          <Divider />
          <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
            <div className="field">
              <label
                htmlFor="username"
                className={classNames({ "p-error": !!errors.username })}
              >
                Usuario
              </label>
              <span className="p-input-icon-right">
                <i className="pi pi-envelope" />
                <Controller
                  name="username"
                  control={control}
                  rules={{
                    required: "El usuario es requerido.",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Correo invalido. Ej: correo@email.com",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
              </span>
              {getFormErrorMessage("username")}
            </div>
            <div className="field">
              <label
                htmlFor="password"
                className={classNames({ "p-error": errors.password })}
              >
                Contraseña
              </label>
              <Controller
                name="password"
                control={control}
                rules={{ required: "La contraseña es requerida." }}
                render={({ field, fieldState }) => (
                  <Password
                    id={field.name}
                    {...field}
                    toggleMask
                    feedback={false}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              {getFormErrorMessage("password")}
            </div>

            <Button
              loading={isLoading}
              type="submit"
              label="Ingresar"
              className="mt-2"
            />

            <Button
              label="Crear cuenta"
              className="p-button-text mt-5"
              onClick={() => {
                navigate("/registro");
              }}
            />
          </form>
          {hasLoginError && (
            <div className="col-12">
              <Message severity="error" text={errorMessage} />
            </div>
          )}
        </Card>
      </div>
    </div>
  );
};
