import React, { useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

import {
  createNewList,
  getListsUsers,
  updateList,
  updateListsUsersByUser,
} from "../../services/lists";
import { ListDetail } from "./ListDetail";
import { useNavigate } from "react-router-dom";
import { ShareModule } from "components/ShareModule";

export const Lists = () => {
  const toast = useRef(null);
  const [listsItems, setListsItems] = useState([]);
  const [newListName, setNewListName] = useState("");
  const [newListNameState, setNewListNameState] = useState(false);
  const [newListLoading, setNewListLoading] = useState(false);

  const [showListDetail, setShowListDetail] = useState(false);
  const [selectedList, setSelectedList] = useState(null);
  const [sharedItem, setSharedItem] = useState(null);
  const [showSharedComponent, setShowSharedComponent] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getLists();
  }, []);

  const getLists = async () => {
    const lists = await getListsUsers();
    setListsItems(lists.data.items);
  };

  const createList = () => {
    if (!newListName) {
      setNewListNameState(true);
    } else {
      setNewListLoading(true);
      createNewList({ name: newListName })
        .then((newList) => {
          const newListsItems = [...listsItems, newList.data];
          setListsItems(newListsItems);
          setNewListLoading(false);
          setNewListName("");
        })
        .catch((err) => {
          setNewListLoading(false);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Hubo un error al guardar la lista",
            sticky: true,
          });
        });
    }
  };

  const confirmDeleteList = (listId) => {
    updateList({ listId: listId, active: 0 })
      .then((updatedList) => {
        const newListsItems = listsItems.filter(
          (list) => list.list.listId !== updatedList.data.listId
        );
        setListsItems(newListsItems);
        toast.current.show({
          severity: "success",
          summary: "Eliminado",
          detail: `La lista "${updatedList.data.name}" fue eliminada con exito`,
          sticky: true,
        });
      })
      .catch((err) => {
        console.error(err);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Hubo un error al eliminar la lista",
          sticky: true,
        });
      });
  };

  const deleteList = (listId, name) => {
    confirmDialog({
      message: `Estas seguro de querer eliminar la lista ${name}?`,
      header: "Eliminar lista",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => confirmDeleteList(listId),
      acceptLabel: "Continuar",
    });
  };

  const confirmGetOutFromList = (idListUser, name) => {
    updateListsUsersByUser({ idListUser: idListUser, active: 0 })
      .then((updatedList) => {
        const newListsItems = listsItems.filter(
          (list) => list.idListUser !== updatedList.data.idListUser
        );
        setListsItems(newListsItems);
        toast.current.show({
          severity: "success",
          summary: "Eliminado",
          detail: `Saliste con exito de la lista "${name}"`,
          sticky: true,
        });
      })
      .catch((err) => {
        console.error(err);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Hubo un error al salir de la lista",
          sticky: true,
        });
      });
  };

  const getOutFromList = (idListUser, name) => {
    confirmDialog({
      message: `Estas seguro de querer salir de la lista ${name}?`,
      header: "Salir de lista",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => confirmGetOutFromList(idListUser, name),
      acceptLabel: "Continuar",
    });
  };

  return (
    <>
      <div className="grid p-3">
        <div className="col-12 px-4 pt-5">
          <h2>Listas</h2>
        </div>
      </div>
      <div className="grid p-3">
        {listsItems.length
          ? listsItems.map((list) => {
              return (
                <div
                  className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4 p-4"
                  key={list.list.listId}
                >
                  <Card
                    title={list.list.name}
                    footer={
                      <span className="d-flex justify-content-end">
                        {list.roleId === 1 && (
                          <Button
                            icon="pi pi-trash"
                            tooltip="Eliminar lista"
                            tooltipOptions={{ position: "top" }}
                            className="p-button-rounded p-button-danger m-1"
                            onClick={() => {
                              deleteList(list.list.listId, list.list.name);
                            }}
                          />
                        )}
                        {(list.roleId === 2 || list.roleId === 3) && (
                          <Button
                            icon="pi pi-sign-out"
                            tooltip="Salir de lista"
                            tooltipOptions={{ position: "top" }}
                            className="p-button-rounded p-button-danger m-1"
                            onClick={() => {
                              getOutFromList(list.idListUser, list.list.name);
                            }}
                          />
                        )}
                        {list.roleId === 1 && (
                          <Button
                            icon="pi pi-share-alt"
                            tooltip="Compartir lista"
                            tooltipOptions={{ position: "top" }}
                            className="p-button-rounded p-button-success m-1"
                            onClick={() => {
                              setShowSharedComponent(true);
                              setSharedItem({
                                id: list.list.listId,
                                name: list.list.name,
                              });
                            }}
                          />
                        )}
                        {(list.roleId === 1 || list.roleId === 2) && (
                          <Button
                            icon="pi pi-list"
                            tooltip="Editar productos de la lista"
                            tooltipOptions={{ position: "top" }}
                            className="p-button-rounded p-button-secondary m-1"
                            onClick={() => {
                              setShowListDetail(true);
                              setSelectedList(list.list.listId);
                            }}
                          />
                        )}
                        <Button
                          icon="pi pi-shopping-cart"
                          tooltip="Usar lista"
                          tooltipOptions={{ position: "top" }}
                          className="p-button-rounded p-button-info m-1"
                          onClick={() => {
                            navigate(`/compras/${list.list.listId}`);
                          }}
                        />
                      </span>
                    }
                  ></Card>
                </div>
              );
            })
          : null}
        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4 p-4">
          <Card
            title="Agregar nueva lista"
            footer={
              <span className="grid justify-content-center">
                <div className="col-8">
                  <InputText
                    type="text"
                    placeholder="Nombre"
                    className={newListNameState ? "p-invalid w-100" : "w-100"}
                    value={newListName}
                    onChange={(e) => {
                      setNewListName(e.target.value);
                      setNewListNameState(false);
                    }}
                  />
                </div>
                <div className="col-2">
                  <Button
                    icon="pi pi-plus"
                    className="p-button-rounded p-button-info"
                    onClick={createList}
                    loading={newListLoading}
                  />
                </div>
              </span>
            }
          ></Card>
        </div>
      </div>
      <Toast ref={toast} />
      <Dialog
        header={
          <span className="d-flex justify-content-start align-items-center">
            <Dropdown
              value={selectedList}
              options={listsItems}
              onChange={(e) => setSelectedList(e.value)}
              optionLabel="list.name"
              optionValue="listId"
              placeholder="Lista"
            />
            <Button
              icon="pi pi-shopping-cart"
              className="p-button-rounded p-button-info ml-2"
              onClick={() => {
                navigate(`/compras/${selectedList}`);
              }}
            />
          </span>
        }
        visible={showListDetail}
        maximizable
        maximized
        modal
        style={{ width: "70vw" }}
        footer={
          <div>
            <Button
              label="Cerrar"
              onClick={() => setShowListDetail(false)}
              autoFocus
            />
          </div>
        }
        onHide={() => setShowListDetail(false)}
      >
        {selectedList ? (
          <ListDetail listId={selectedList} toast={toast} />
        ) : (
          "Seleccione una lista"
        )}
      </Dialog>
      {showSharedComponent && (
        <ShareModule
          module="list"
          item={sharedItem}
          toast={toast}
          showDialog={showSharedComponent}
          setShowDialog={setShowSharedComponent}
        />
      )}
    </>
  );
};
