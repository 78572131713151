import axios from 'axios';

export const getProductsListsByList = async (listId)=>{
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_URL_API}/products-lists/list/${listId}`)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const updateProductsLists = (data)=>{
  const {idProductsLists, ...params} = data;
  return new Promise((resolve, reject) => {
    axios.patch(`${process.env.REACT_APP_URL_API}/products-lists/${idProductsLists}`,params)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const getProductsListsByListsGroup = async (lists)=>{
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_URL_API}/products-lists/group-list`,lists)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const createNewProductsLists = async (data)=>{
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_URL_API}/products-lists`,data)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}
