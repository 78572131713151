import React, { useState } from "react";
import { Card } from "primereact/card";
import { ListBox } from "primereact/listbox";

export const UserTheme = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const languages = [
    { name: "Claro", code: "light" },
    { name: "Oscuro", code: "dark" },
  ];
  return (
    <>
      <Card title="Tema (Desarrollo)" className="mb-2">
        <ListBox
          value={selectedLanguage}
          options={languages}
          onChange={(e) => setSelectedLanguage(e.value)}
          optionLabel="name"
          style={{ width: "15rem" }}
        />
      </Card>
    </>
  );
};
