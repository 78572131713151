import React, { useState } from 'react'
import axios from 'axios';

const Context = React.createContext({})

export const UserContextProvider = ({children}) => {
  const [token, setToken] = useState(()=>window.localStorage.getItem('access_token'));
  const [user, setUser] = useState(()=>JSON.parse(window.localStorage.getItem('user')));

  axios.interceptors.request.use(
    async request => {
      const apitoken = window.localStorage.getItem('access_token');
      if (apitoken) {
        request.headers["Authorization"] = `Bearer ${apitoken}`;
      }
      return Promise.resolve(request);
    },
    error => {
      return Promise.reject(error);
    }
  )

  return <Context.Provider value={{token, setToken, user, setUser}}>{children}</Context.Provider>
}

export default Context