import React, { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import { ProgressSpinner } from "primereact/progressspinner";
import { Messages } from "primereact/messages";
import { Divider } from "primereact/divider";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { useUser } from "../hooks/useUser";
import { useNavigate } from "react-router-dom";
import { signupService } from "../services/signup";
import { Dialog } from "primereact/dialog";

export const Signup = () => {
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const message = useRef(null);
  const defaultValues = {
    username: "",
    name: "",
    lastName: "",
    email: "",
    password: "",
    passwordConfirmation: "",
  };

  const { isLogged } = useUser();
  const navigate = useNavigate();

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm({ defaultValues });

  useEffect(() => {
    if (isLogged) {
      navigate("/");
    }
  }, [isLogged, navigate]);

  const onSubmit = (data) => {
    resetMessage();
    setLoading(true);
    signupService(data)
      .then((resSignup) => {
        console.log(resSignup);
        if (resSignup.status === 200) {
          message.current.show([
            {
              severity: "success",
              detail: "El usuario fue registrado exitosamente.",
              sticky: true,
            },
          ]);
          reset();
        } else {
          message.current.show([
            {
              severity: "warn",
              detail:
                "Hubo un error al crear la cuenta, intenta nuevamente más tarde",
              sticky: true,
            },
          ]);
        }
        setShowMessage(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setShowMessage(true);
        message.current.show([
          {
            severity: "error",
            detail:
              err.response?.data?.message ||
              "Hubo un error al crear la cuenta, intenta nuevamente mas tarde",
            sticky: true,
          },
        ]);
        setLoading(false);
      });
  };

  const resetMessage = () => {
    if (message.current) {
    message.current.clear();
    }
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  return (
    <div className="grid justify-content-center align-items-center">
      <div className="col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4 p-3 d-mobile-none">
        <div className="d-flex" style={{ height: "700px" }}>
          <img
            src="/img/front_market_1024.png"
            alt="Imagen aplicación lista de compras"
            style={{ margin: "auto", width: "80%" }}
          />
        </div>
      </div>
      <div className="col-12 sm:col-12 md:col-7 lg:col-7 xl:col-7 p-3">
        <Card>
          <h5 className="text-center">Registro</h5>
          <Divider />
          <form
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit, resetMessage)}
            className="p-fluid grid"
          >
            <div className="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
              <label
                htmlFor="name"
                className={classNames({ "p-error": errors.name })}
              >
                Nombre
              </label>
              <Controller
                name="name"
                control={control}
                rules={{ required: "El nombre es obligatorio." }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.name}
                    {...field}
                    autoFocus
                    className={classNames({ "p-invalid": fieldState.invalid })}
                  />
                )}
              />
              {getFormErrorMessage("name")}
            </div>
            <div className="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
              <label
                htmlFor="lastName"
                className={classNames({ "p-error": errors.name })}
              >
                Apellido
              </label>
              <Controller
                name="lastName"
                control={control}
                rules={{ required: "El apellido es obligatorio." }}
                render={({ field, fieldState }) => (
                  <InputText
                    id={field.lastName}
                    {...field}
                    autoFocus
                    className={classNames({ "p-invalid": fieldState.invalid })}
                  />
                )}
              />
              {getFormErrorMessage("lastName")}
            </div>
            <div className="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
              <label
                htmlFor="email"
                className={classNames({ "p-error": !!errors.email })}
              >
                Correo
              </label>
              <span className="p-input-icon-right">
                <i className="pi pi-envelope" />
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "El correo es requerido.",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Correo invalido. Ej. correo@email.com",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      autoComplete="false"
                      id={field.name}
                      {...field}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
              </span>
              {getFormErrorMessage("email")}
            </div>
            <div className="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
              <label
                htmlFor="password"
                className={classNames({ "p-error": errors.password })}
              >
                Contraseña
              </label>
              <Controller
                name="password"
                control={control}
                rules={{ required: "La contraseña es requerida." }}
                render={({ field, fieldState }) => (
                  <Password
                    id={field.name}
                    {...field}
                    toggleMask
                    promptLabel="Ingrese una contraseña"
                    weakLabel="Nivel de seguridad bajo"
                    mediumLabel="Nivel de seguridad medio"
                    strongLabel="Nivel de seguridad alto"
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              {getFormErrorMessage("password")}
            </div>
            <div className="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
              <label
                htmlFor="passwordConfirmation"
                className={classNames({
                  "p-error": errors.passwordConfirmation,
                })}
              >
                Confirmar Contraseña
              </label>
              <Controller
                name="passwordConfirmation"
                control={control}
                rules={{
                  validate: {
                    matchesPreviousPassword: (value) => {
                      const { password } = getValues();
                      return (
                        password === value || "Las contraseñas no coinciden!"
                      );
                    },
                  },
                }}
                render={({ field, fieldState }) => (
                  <Password
                    id={field.name}
                    {...field}
                    toggleMask
                    feedback={false}
                    className={classNames({
                      "p-invalid": fieldState.invalid,
                    })}
                  />
                )}
              />
              {getFormErrorMessage("passwordConfirmation")}
            </div>
            <div className="field-checkbox col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 align-items-center mt-5">
              <Controller
                name="accept"
                control={control}
                rules={{ required: true }}
                render={({ field, fieldState }) => (
                  <Checkbox
                    inputId={field.name}
                    onChange={(e) => field.onChange(e.checked)}
                    checked={field.value}
                    className={classNames({ "p-invalid": fieldState.invalid })}
                  />
                )}
              />
              <label
                htmlFor="accept"
                className={classNames({ "p-error": errors.accept })}
              >
                Acepto terminos y condiciones
              </label>
              <Button
                label="ver"
                className="p-button-link"
                style={{ width: "60px" }}
                onClick={(e) => {
                  e.preventDefault();
                  setShowTermsAndConditions(true);
                }}
              />
            </div>

            {loading ? (
              <ProgressSpinner style={{ width: "60px", height: "60px" }} />
            ) : (
              <Button
                disabled={loading}
                type="submit"
                label="Registrarme"
                className="mt-2"
              />
            )}
            <Button
              label="Iniciar sesión"
              className="p-button-text mt-3"
              onClick={() => {
                navigate("/login");
              }}
            />
          </form>
          {showMessage && (
            <div className="col-12">
              <Messages ref={message} />
            </div>
          )}
        </Card>
      </div>
      <Dialog
        header="Terminos y Condiciones"
        visible={showTermsAndConditions}
        style={{ width: "50vw" }}
        onHide={() => setShowTermsAndConditions(false)}
      >
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </p>
      </Dialog>
    </div>
  );
};
