import React, { useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

import { getPantriesUsers } from "../../services/productsPantries";
import { PantryDetail } from "./PantryDetail";
import { createNewPantry, updatePantriesUsersByUser, updatePantry } from "../../services/pantries";
import { ShareModule } from "components/ShareModule";

export const Pantries = () => {
  const toast = useRef(null);
  const [pantriesItems, setPantriesItems] = useState([]);
  const [newPantryName, setNewPantryName] = useState("");
  const [newPantryNameState, setNewPantryNameState] = useState(false);
  const [newPantryLoading, setNewPantryLoading] = useState(false);

  const [showPantryDetail, setShowPantryDetail] = useState(false);
  const [selectedPantry, setSelectedPantry] = useState(null);
  const [sharedItem, setSharedItem] = useState(null);
  const [showSharedComponent, setShowSharedComponent] = useState(false);

  useEffect(() => {
    getPantries();
  }, []);

  const getPantries = async () => {
    const pantries = await getPantriesUsers();
    setPantriesItems(pantries.data);
    if (pantries.data.length===0) {
      createNewPantry({ name: "Despensa" })
        .then((newPantry) => {
          const newPantriesItems = [...pantriesItems, newPantry.data];
          setPantriesItems(newPantriesItems);
        })
        .catch((err) => {
          console.error("Error en la creación de la despensa inicial");
        });
    }
  };

  const createPantry = () => {
    if (!newPantryName) {
      setNewPantryNameState(true);
    } else {
      setNewPantryLoading(true);
      createNewPantry({ name: newPantryName })
        .then((newPantry) => {
          const newPantriesItems = [...pantriesItems, newPantry.data];
          setPantriesItems(newPantriesItems);
          setNewPantryLoading(false);
          setNewPantryName("");
        })
        .catch((err) => {
          setNewPantryLoading(false);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Hubo un error al guardar la despensa",
            sticky: true,
          });
        });
    }
  };

  const confirmDeletePantry = (pantryId) => {
    updatePantry({ pantryId: pantryId, active: 0 })
      .then((updatedPantry) => {
        const newPantriesItems = pantriesItems.filter(
          (pantry) => pantry.pantry.pantryId !== updatedPantry.data.pantryId
        );
        setPantriesItems(newPantriesItems);
        toast.current.show({
          severity: "success",
          summary: "Eliminado",
          detail: `La despensa "${updatedPantry.data.name}" fue eliminada con exito`,
          sticky: true,
        });
      })
      .catch((err) => {
        console.error(err);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: err.response?.data?.message || "Hubo un error al eliminar la despensa",
          sticky: true,
        });
      });
  };

  const deletePantry = (pantryId, name) => {
    confirmDialog({
      message: `Estas seguro de querer eliminar la lista ${name}?`,
      header: "Eliminar lista",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => confirmDeletePantry(pantryId),
      acceptLabel: "Continuar",
    });
  };

  const confirmGetOutFromPantry = (idPantryUser, name) => {
    updatePantriesUsersByUser({ idPantryUser: idPantryUser, active: 0 })
      .then((updatedPantry) => {
        const newPantriesItems = pantriesItems.filter(
          (pantry) => pantry.idPantryUser !== updatedPantry.data.idPantryUser
        );
        setPantriesItems(newPantriesItems);
        toast.current.show({
          severity: "success",
          summary: "Eliminado",
          detail: `Saliste con exito de la despensa "${name}"`,
          sticky: true,
        });
      })
      .catch((err) => {
        console.error(err);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Hubo un error al salir de la despensa",
          sticky: true,
        });
      });
  };

  const getOutFromPantry = (idPantryUser, name) => {
    confirmDialog({
      message: `Estas seguro de querer salir de la lista ${name}?`,
      header: "Salir de lista",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => confirmGetOutFromPantry(idPantryUser, name),
      acceptLabel: "Continuar",
    });
  };

  return (
    <>
      <div className="grid p-3">
        <div className="col-12 px-4 pt-5">
          <h2>Despensas</h2>
        </div>
      </div>
      <div className="grid p-3">
        {pantriesItems.length
          ? pantriesItems.map((pantry) => {
              return (
                <div
                  className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4 p-4"
                  key={pantry.pantry.pantryId}
                >
                  <Card
                    title={pantry.pantry.name}
                    footer={
                      <span className="d-flex justify-content-end">
                        {pantry.roleId === 1 && (
                          <Button
                            icon="pi pi-trash"
                            tooltip="Eliminar despensa" 
                            tooltipOptions={{position: 'top'}}
                            className="p-button-rounded p-button-danger m-1"
                            onClick={() => {
                              deletePantry(pantry.pantry.pantryId, pantry.pantry.name);
                            }}
                          />
                        )}
                        {(pantry.roleId === 2 || pantry.roleId === 3) && (
                          <Button
                            icon="pi pi-sign-out"
                            tooltip="Salir de despensa compartida" 
                            tooltipOptions={{position: 'top'}}
                            className="p-button-rounded p-button-danger m-1"
                            onClick={() => {
                              getOutFromPantry(pantry.idPantryUser, pantry.pantry.name);
                            }}
                          />
                        )}
                        {pantry.roleId === 1 && (
                          <Button
                            icon="pi pi-share-alt"
                            tooltip="Compartir despensa" 
                            tooltipOptions={{position: 'top'}}
                            className="p-button-rounded p-button-success m-1"
                            onClick={() => {
                              setShowSharedComponent(true);
                              setSharedItem({
                                id: pantry.pantry.pantryId,
                                name: pantry.pantry.name,
                              });
                            }}
                          />
                        )}
                        {(pantry.roleId === 1 || pantry.roleId === 2) && (
                          <Button
                            icon="pi pi-list"
                            tooltip="Editar productos de despensa" 
                            tooltipOptions={{position: 'top'}}
                            className="p-button-rounded p-button-secondary m-1"
                            onClick={() => {
                              setShowPantryDetail(true);
                              setSelectedPantry(pantry.pantry.pantryId);
                            }}
                          />
                        )}
                      </span>
                    }
                  ></Card>
                </div>
              );
            })
          : null}
        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-4 p-4">
          <Card
            title="Agregar nueva despensa"
            footer={
              <span className="grid justify-content-center">
                <div className="col-8">
                  <InputText
                    type="text"
                    placeholder="Nombre"
                    className={newPantryNameState ? "p-invalid w-100" : "w-100"}
                    value={newPantryName}
                    onChange={(e) => {
                      setNewPantryName(e.target.value);
                      setNewPantryNameState(false);
                    }}
                  />
                </div>
                <div className="col-2">
                  <Button
                    icon="pi pi-plus"
                    className="p-button-rounded p-button-info"
                    onClick={createPantry}
                    loading={newPantryLoading}
                  />
                </div>
              </span>
            }
          ></Card>
        </div>
      </div>
      <Toast ref={toast} />
      <Dialog
        header={
          <span className="d-flex justify-content-start align-items-baseline">
            <Dropdown
              value={selectedPantry}
              options={pantriesItems}
              onChange={(e) => setSelectedPantry(e.value)}
              optionLabel="pantry.name"
              optionValue="pantryId"
              placeholder="Despensa"
            />
          </span>
        }
        visible={showPantryDetail}
        maximizable
        maximized
        modal
        style={{ width: "70vw" }}
        footer={
          <div>
            <Button
              label="Cerrar"
              onClick={() => setShowPantryDetail(false)}
              autoFocus
            />
          </div>
        }
        onHide={() => setShowPantryDetail(false)}
      >
        {selectedPantry ? (
          <PantryDetail
            pantryId={selectedPantry}
            toast={toast}
          />
        ) : (
          "Seleccione una lista"
        )}
      </Dialog>
      {showSharedComponent && (
        <ShareModule
          module="pantry"
          item={sharedItem}
          toast={toast}
          showDialog={showSharedComponent}
          setShowDialog={setShowSharedComponent}
        />
      )}
    </>
  );
};
