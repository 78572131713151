import { useCallback, useContext, useState } from "react";
import  Context from "../context/UserContext";
import {loginService} from "../services/login";

export const useUser = () => {
  const {token, setToken, user, setUser} = useContext(Context)
  const [state, setState] = useState({loading:false, error:false, errorMessage:''})
  const login = useCallback((data)=>{
    setState({loading:true,error:false});
    loginService(data).then(resToken=>{
      if (resToken.data.access_token) {
        window.localStorage.setItem('access_token',resToken.data.access_token)
        window.localStorage.setItem('user',JSON.stringify(resToken.data.user))
        setToken(resToken.data.access_token)
        setState({loading:false,error:false});
        setUser(resToken.data.user)
      }else{
        window.localStorage.removeItem('access_token')
        window.localStorage.removeItem('user')
        setState({loading:false,error:true});
      }
    }).catch(err=>{
      window.localStorage.removeItem('access_token')
      window.localStorage.removeItem('user')
      setState({loading:false,error:true, errorMessage:err.response?.data?.message});
    })
  },[setToken])
  const logout = useCallback(()=>{
    window.localStorage.removeItem('access_token')
    window.localStorage.removeItem('user')
    setToken(null)
  },[setToken])
  return {isLogged:Boolean(token),isLoading:state.loading,hasLoginError:state.error,errorMessage:state.errorMessage,login,logout, user};
}