import React, { useEffect, useState } from "react";
import moment from "moment";
import { Card } from "primereact/card";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getPantriesUsers, getProductsPantriesCloseToExpiration } from "../services/productsPantries";
import { createNewPantry } from "services/pantries";

export const Home = () => {
  const [productsPantriesList, setProductsPantriesList] = useState([]);
  const [productsCloseToExpiration, setProductsCloseToExpiration] = useState(
    []
  );
  const [expiredProducts, setExpiredProducts] = useState([]);

  useEffect(() => {
    getProductsPantries();
    validatePantries();
  }, []);

  const getProductsPantries = async () => {
    const productsPantries = await getProductsPantriesCloseToExpiration();
    setProductsPantriesList(productsPantries.data.products);
    setProductsCloseToExpiration(productsPantries.data.closeToExpiration);
    setExpiredProducts(productsPantries.data.expiredProducts);
  };

  const validatePantries = async () => {
    const pantries = await getPantriesUsers();
    if (pantries.data.length===0) {
      createNewPantry({ name: "Despensa" })
        .then((newPantry) => {
          console.log("despensa inicial creada");
        })
        .catch((err) => {
          console.error("Error en la creación de la despensa inicial");
        });
    }
  };

  const expirationTemplate = (rowData) => {
    return moment(rowData.expiration).format("DD/MM/YYYY");
  };

  return (
    <>
      <div className="grid">
        <div className="col-12 md:col-6 lg:col-3 p-4">
          <h2>Bienvenido!</h2>
        </div>
      </div>
      <div className="grid">
        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 p-4">
          <Card title="Productos próximos a vencer">
            <DataTable
              value={productsCloseToExpiration}
              responsiveLayout="scroll"
              emptyMessage={"No hay productos próximos a vencer"}
            >
              <Column field="product.name" header="Nombre"></Column>
              <Column field="pantry.name" header="Despensa"></Column>
              <Column
                body={expirationTemplate}
                header="Fecha de vencimiento"
              ></Column>
              <Column field="quantity" header="Cantidad"></Column>
            </DataTable>
          </Card>
        </div>
        <div className="col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6 p-4">
          <Card title="Productos vencidos">
            <DataTable
              value={expiredProducts}
              responsiveLayout="scroll"
              emptyMessage={"No hay productos vencidos"}
            >
              <Column field="product.name" header="Nombre"></Column>
              <Column field="pantry.name" header="Despensa"></Column>
              <Column
                body={expirationTemplate}
                header="Fecha de vencimiento"
              ></Column>
              <Column field="quantity" header="Cantidad"></Column>
            </DataTable>
          </Card>
        </div>
      </div>
    </>
  );
};
