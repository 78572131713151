import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { classNames } from "primereact/utils";
import { ProgressSpinner } from "primereact/progressspinner";
import { Messages } from "primereact/messages";
import { Divider } from "primereact/divider";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { useUser } from "hooks/useUser";
import { useNavigate } from "react-router-dom";
import { signupService } from "services/signup";
import { Dialog } from "primereact/dialog";
import { updateUser } from "services/user";
import Context from "context/UserContext";

export const EditUser = () => {
  const { user, setUser } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const message = useRef(null);
  const defaultValues = {
    name: "",
    lastName: "",
    currentPassword: "",
    password: "",
    passwordConfirmation: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    getValues,
    reset,
  } = useForm({ defaultValues });

  const onSubmit = (data) => {
    let existsData = false;
    resetMessage();
    setLoading(true);
    /* if (data.password === "") {
      delete data["password"];
    } */
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const element = data[key];
        if (!element) {
          delete data[key];
        } else {
          existsData = true;
        }
      }
    }
    console.log(data);
    if (!existsData) {
      setLoading(false);
      setShowMessage(true);
      message.current.show([
        {
          severity: "info",
          detail: "No hay información para cambiar.",
          sticky: true,
        },
      ]);
      return;
    }
    updateUser(user.idUser, data)
      .then((resUpdateuser) => {
        console.log(resUpdateuser);
        if (resUpdateuser.status === 200) {
          message.current.show([
            {
              severity: "success",
              detail: "El usuario fue editado exitosamente.",
              sticky: true,
            },
          ]);
          setUser(resUpdateuser.data);
          window.localStorage.setItem(
            "user",
            JSON.stringify(resUpdateuser.data)
          );
          reset();
        } else {
          message.current.show([
            {
              severity: "warn",
              detail:
                "Hubo un error al editar al usuario, intenta nuevamente más tarde",
              sticky: true,
            },
          ]);
        }
        setShowMessage(true);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setShowMessage(true);
        message.current.show([
          {
            severity: "error",
            detail:
              err.response?.data?.message ||
              "Hubo un error al editar al usuario, intenta nuevamente mas tarde",
            sticky: true,
          },
        ]);
        setLoading(false);
      });
  };

  const resetMessage = () => {
    if (message.current) {
      message.current.clear();
    }
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  return (
    <Card title="Ediar Usuario">
      <form
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit, resetMessage)}
        className="p-fluid grid"
      >
        <div className="col-12">
          <h5>Datos de Usuario</h5>
        </div>
        <div className="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
          <label
            htmlFor="name"
            className={classNames({ "p-error": errors.name })}
          >
            Nombre
          </label>
          <Controller
            name="name"
            control={control}
            render={({ field, fieldState }) => (
              <InputText
                id={field.name}
                {...field}
                autoFocus
                className={classNames({
                  "p-invalid": fieldState.invalid,
                })}
              />
            )}
          />
          {getFormErrorMessage("name")}
        </div>
        <div className="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
          <label
            htmlFor="lastName"
            className={classNames({ "p-error": errors.name })}
          >
            Apellido
          </label>
          <Controller
            name="lastName"
            control={control}
            render={({ field, fieldState }) => (
              <InputText
                id={field.lastName}
                {...field}
                className={classNames({
                  "p-invalid": fieldState.invalid,
                })}
              />
            )}
          />
          {getFormErrorMessage("lastName")}
        </div>
        <Divider />
        <div className="col-12">
          <h5>Contraseña</h5>
        </div>
        <div className="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
          <label
            htmlFor="password"
            className={classNames({ "p-error": errors.currentPassword })}
          >
            Contraseña Acual
          </label>
          <Controller
            name="currentPassword"
            control={control}
            rules={{
              validate: {
                hasPassword: (value) => {
                  const { password } = getValues();
                  return (
                    value !== "" ||
                    (password !== "" && value !== "") ||
                    (password === "" && value === "") ||
                    "La contraseña actual es requerida"
                  );
                },
              },
            }}
            render={({ field, fieldState }) => (
              <Password
                id={field.name}
                {...field}
                toggleMask
                feedback={false}
                className={classNames({
                  "p-invalid": fieldState.invalid,
                })}
              />
            )}
          />
          {getFormErrorMessage("currentPassword")}
        </div>
        <div className="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
          <label
            htmlFor="password"
            className={classNames({ "p-error": errors.password })}
          >
            Contraseña Nueva
          </label>
          <Controller
            name="password"
            control={control}
            rules={{
              validate: {
                hasCurrentPassword: (value) => {
                  const { currentPassword } = getValues();
                  return (
                    value !== "" ||
                    (currentPassword !== "" && value !== "") ||
                    (currentPassword === "" && value === "") ||
                    "La contraseña nueva es requerida"
                  );
                },
              },
            }}
            render={({ field, fieldState }) => (
              <Password
                id={field.name}
                {...field}
                toggleMask
                promptLabel="Ingrese una contraseña nueva"
                weakLabel="Nivel de seguridad bajo"
                mediumLabel="Nivel de seguridad medio"
                strongLabel="Nivel de seguridad alto"
                className={classNames({
                  "p-invalid": fieldState.invalid,
                })}
              />
            )}
          />
          {getFormErrorMessage("password")}
        </div>
        <div className="field col-12 sm:col-12 md:col-6 lg:col-6 xl:col-6">
          <label
            htmlFor="passwordConfirmation"
            className={classNames({
              "p-error": errors.passwordConfirmation,
            })}
          >
            Confirmar Contraseña
          </label>
          <Controller
            name="passwordConfirmation"
            control={control}
            rules={{
              validate: {
                matchesPreviousPassword: (value) => {
                  const { password } = getValues();
                  return password === value || "Las contraseñas no coinciden!";
                },
              },
            }}
            render={({ field, fieldState }) => (
              <Password
                id={field.name}
                {...field}
                toggleMask
                feedback={false}
                className={classNames({
                  "p-invalid": fieldState.invalid,
                })}
              />
            )}
          />
          {getFormErrorMessage("passwordConfirmation")}
        </div>
        <Divider />
        {showMessage && (
          <div className="col-12">
            <Messages ref={message} />
          </div>
        )}
        <div className="field col-12">
          <Button type="submit" label="Guardar" loading={loading} />
        </div>
      </form>
    </Card>
  );
};
