import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import {
  createListsUsers,
  getListsUsersByListId,
  updateListsUsers,
} from "services/lists";
import {
  createPantriesUsers,
  getListsUsersByPantryId,
  updatePantriesUsers,
} from "services/pantries";
import { getRoles } from "services/roles";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";

export const ShareModule = ({
  module,
  item,
  toast,
  showDialog,
  setShowDialog,
}) => {
  const [usersWithAccess, setUsersWithAccess] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const defaultValues = {
    user: "",
    role: "",
  };
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({ defaultValues });

  useEffect(
    (
      getRolesListFunc = getRolesList,
      getUsersWithAccessFunc = getUsersWithAccess
    ) => {
      getUsersWithAccessFunc();
      getRolesListFunc();
    },
    []
  );

  const getUsersWithAccess = async () => {
    if (module) {
      if (module === "list") {
        getUsersWithAccessList();
      }
      if (module === "pantry") {
        getUsersWithAccessPantry();
      }
    }
  };

  const getUsersWithAccessList = async () => {
    const lists = await getListsUsersByListId(item.id);
    setUsersWithAccess(lists.data);
  };

  const getUsersWithAccessPantry = async () => {
    const lists = await getListsUsersByPantryId(item.id);
    setUsersWithAccess(lists.data);
  };

  const getRolesList = async () => {
    const rolesRes = await getRoles();
    var roles = rolesRes.data.filter((role) => role.roleId !== 1);
    if (module === "pantry") {
      roles = roles.filter((role) => role.roleId !== 3);
    }
    setRolesList(roles);
  };

  const actionsTemplate = (rowData) => {
    return (
      rowData.roleId !== 1 && (
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger m-1"
          onClick={() => {
            var type = "";
            if (module) {
              if (module === "list") {
                type = "lista";
              }
              if (module === "pantry") {
                type = "despensa";
              }
            }
            confirmDialog({
              message: `Estas seguro de retirar el acceso a la ${type} ${item.name} al usuario ${rowData.user.name} ${rowData.user.lastName}?`,
              header: `Eliminar usuario de ${type}`,
              icon: "pi pi-info-circle",
              acceptClassName: "p-button-danger",
              accept: () => {
                if (module === "list") {
                  confirmGetOutFromList(rowData.idListUser);
                }
                if (module === "pantry") {
                  confirmGetOutFromPantry(rowData.idPantryUser);
                }
              },
              acceptLabel: "Continuar",
            });
          }}
        />
      )
    );
  };

  const confirmGetOutFromList = (idListUser) => {
    updateListsUsers({ idListUser: idListUser, active: 0 })
      .then((updatedList) => {
        getUsersWithAccess();
        toast.current.show({
          severity: "success",
          summary: "Eliminado",
          detail: `Se eliminó el usuario de la lista "${item.name}"`,
          sticky: true,
        });
      })
      .catch((err) => {
        console.error(err);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Hubo un error al eliminar al usuario de la lista",
          sticky: true,
        });
      });
  };

  const confirmGetOutFromPantry = (idPantryUser) => {
    updatePantriesUsers({ idPantryUser: idPantryUser, active: 0 })
      .then((updatedPantry) => {
        getUsersWithAccess();
        toast.current.show({
          severity: "success",
          summary: "Eliminado",
          detail: `Se eliminó el usuario de la despensa "${item.name}"`,
          sticky: true,
        });
      })
      .catch((err) => {
        console.error(err);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Hubo un error al eliminar al usuario de la despensa",
          sticky: true,
        });
      });
  };

  const userTemplate = (rowData) => {
    return `${rowData.user.name} ${rowData.user.lastName}`;
  };

  const header = (
    <div className="table-header d-flex align-items-center justify-content-space-between grid"></div>
  );

  const onSubmit = (data) => {
    /* var params = {};
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const element = data[key];
        if (element) {
          params[key] = element;
        }
      }
    } */
    if (module) {
      if (module === "list") {
        saveAccessList(data);
      }
      if (module === "pantry") {
        saveAccessPantry(data);
      }
    }
  };

  const saveAccessList = async (body) => {
    const params = {
      listId: item.id,
      user: body.user,
      roleId: body.role,
    };
    createListsUsers(params)
      .then((res) => {
        getUsersWithAccess();
        reset();
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail:
            err.response?.data?.message ||
            "Hubo un error al compartir la lista",
          sticky: true,
        });
      });
  };

  const saveAccessPantry = async (body) => {
    const params = {
      pantryId: item.id,
      user: body.user,
      roleId: body.role,
    };
    createPantriesUsers(params)
      .then((res) => {
        getUsersWithAccess();
        reset();
      })
      .catch((err) => {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail:
            err.response?.data?.message ||
            "Hubo un error al compartir la despensa",
          sticky: true,
        });
      });
  };

  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  return (
    <>
      <Dialog
        header={`Compartir lista ${item.name}`}
        visible={showDialog}
        footer={
          <div>
            <Button
              label="Cerrar"
              onClick={() => setShowDialog(false)}
              autoFocus
            />
          </div>
        }
        onHide={() => setShowDialog(false)}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
          <div className="grid">
            <div className="col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4">
              <div className="field">
                <label
                  htmlFor="user"
                  className={classNames({ "p-error": errors.user })}
                >
                  Correo del usuario
                </label>
                <Controller
                  name="user"
                  control={control}
                  rules={{
                    required: "El usuario es requerido.",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Correo invalido. Ej: correo@email.com",
                    },
                  }}
                  render={({ field, fieldState }) => (
                    <InputText
                      id={field.user}
                      {...field}
                      autoFocus
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                    />
                  )}
                />
                {getFormErrorMessage("user")}
              </div>
            </div>
            <div className="col-12 sm:col-12 md:col-4 lg:col-4 xl:col-4">
              <div className="field">
                <label
                  htmlFor="role"
                  className={classNames({ "p-error": errors.role })}
                >
                  Nivel de permisos
                </label>
                <Controller
                  name="role"
                  control={control}
                  rules={{
                    required: "Campo es requerido.",
                  }}
                  render={({ field, fieldState }) => (
                    <Dropdown
                      id={field.name}
                      value={field.value}
                      onChange={(e) => field.onChange(e.value)}
                      options={rolesList}
                      className={classNames({
                        "p-invalid": fieldState.invalid,
                      })}
                      optionLabel="roleName"
                      optionValue="roleId"
                    />
                  )}
                />
                {getFormErrorMessage("role")}
              </div>
            </div>
            <div className="col-10 sm:col-10 md:col-3 lg:col-3 xl:col-3 mt-4">
              <Button type="submit" label="Compartir" className="mt-2" />
            </div>
            <div className="col-2 sm:col-2 md:col-1 lg:col-1 xl:col-1 mt-4">
              <Button
                icon="pi pi-link"
                className="p-button-rounded mt-2"
                tooltip="Enlace de un solo uso (desarrollo)"
                tooltipOptions={{ position: "top" }}
              />
            </div>
          </div>
        </form>

        <DataTable
          value={usersWithAccess}
          /* header={header} */
          responsiveLayout="scroll"
          stripedRows
          emptyMessage={"No se encontraron productos en la lista"}
          dataKey={module === "list" ? "idListUser" : "idPantryUser"}
        >
          <Column field="user.email" header="Usuario"></Column>
          <Column header="Nombre" body={userTemplate}></Column>
          <Column field="role.roleName" header="Rol"></Column>
          <Column header="Eliminar" body={actionsTemplate}></Column>
        </DataTable>
      </Dialog>
    </>
  );
};
