import { useUser } from "hooks/useUser";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import React from "react";

export const UserPlan = () => {
  const { user } = useUser();
  return (
    <>
      <Card>
        <div className="surface-0">
          <h1 className="text-900 font-bold text-6xl mb-4 text-center">
            Planes
          </h1>
          <div className="text-700 text-xl mb-6 text-center line-height-3">
            Conoce las opciones que tenemos para ti.
          </div>

          <div className="grid">
            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div
                  className="shadow-2 p-3 h-full flex flex-column"
                  style={{ borderRadius: "6px" }}
                >
                  <div className="text-900 font-medium text-xl mb-2">
                    Gratis
                  </div>
                  <div className="text-600"></div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">$0</span>
                    <span className="ml-2 font-medium text-600">por mes</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Podrás crear 2 listas</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Máximo 30 productos por lista</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Compartir listas</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Podrás tener 1 despensa</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Máximo 50 productos por despensa</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Compartir despensas</span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300 mt-auto" />
                  <Button
                    disabled={user.plan === 1}
                    label={user.plan === 1 ? "Plan Actual" : "Obtener"}
                    className="p-3 w-full mt-auto"
                  />
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div
                  className="shadow-2 p-3 h-full flex flex-column"
                  style={{ borderRadius: "6px" }}
                >
                  <div className="text-900 font-medium text-xl mb-2">
                    Premium
                  </div>
                  <div className="text-600"></div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">
                      $3.000 COP
                    </span>
                    <span className="ml-2 font-medium text-600">por mes</span>
                  </div>
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">
                      $30.000 COP
                    </span>
                    <span className="ml-2 font-medium text-600">por año</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Listas ilimitadas</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Productos por lista ilimitados</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Compartir listas</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Podrás tener hasta 3 despensas</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Máximo 50 productos por despensa</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Compartir despensas</span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <Button
                    disabled={user.plan === 2}
                    label={user.plan === 2 ? "Plan Actual" : "Obtener"}
                    className="p-3 w-full mt-auto"
                  />
                </div>
              </div>
            </div>

            <div className="col-12 lg:col-4">
              <div className="p-3 h-full">
                <div
                  className="shadow-2 p-3 flex flex-column"
                  style={{ borderRadius: "6px" }}
                >
                  <div className="text-900 font-medium text-xl mb-2">
                    Enterprise
                  </div>
                  <div className="text-600"></div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">
                      $5.000 COP
                    </span>
                    <span className="ml-2 font-medium text-600">por mes</span>
                  </div>
                  <div className="flex align-items-center">
                    <span className="font-bold text-2xl text-900">
                      $50.000 COP
                    </span>
                    <span className="ml-2 font-medium text-600">por año</span>
                  </div>
                  <hr className="my-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <ul className="list-none p-0 m-0 flex-grow-1">
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Listas ilimitadas</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Productos por lista ilimitados</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Compartir listas</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Despensas ilimitadas</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Productos por despensa ilimitados</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Compartir despensas</span>
                    </li>
                    <li className="flex align-items-center mb-3">
                      <i className="pi pi-check-circle text-green-500 mr-2"></i>
                      <span>Estadisticas de compras</span>
                    </li>
                  </ul>
                  <hr className="mb-3 mx-0 border-top-1 border-bottom-none border-300" />
                  <Button
                    disabled={user.plan === 3}
                    label={user.plan === 3 ? "Plan Actual" : "Obtener"}
                    className="p-3 w-full p-button-outlined"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};
