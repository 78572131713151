import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Avatar } from "primereact/avatar";
import { InputNumber } from "primereact/inputnumber";
import { getProductsListsByList } from "../../services/productsLists";
import { useParams } from "react-router-dom";
import { Card } from "primereact/card";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { addLocale } from "primereact/api";
import { createPurchaseRecord } from "../../services/purchaseRecords";
import {
  bulkCreateProductsPantries,
  getPantriesUsers,
} from "../../services/productsPantries";
import { updateProduct } from "../../services/products";

export const Shopping = () => {
  const toast = useRef(null);
  const [pantriesItems, setPantriesItems] = useState([]);
  const [selectedPantry, setSelectedPantry] = useState(null);
  const [selectedPantryState, setSelectedPantryState] = useState(false);
  const [listName, setListName] = useState("");
  const [products, setProducts] = useState([]);
  const [itemsChecked, setItemsChecked] = useState([]);
  const [purchaseCompleted, setPurchaseCompleted] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);
  const params = useParams();
  const currentDate = new Date();

  addLocale("es", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
    today: "Hoy",
    clear: "Borrar",
  });

  useEffect(() => {
    getPantries();
  }, []);

  const getPantries = async () => {
    const pantries = await getPantriesUsers();
    setPantriesItems(pantries.data);
  };

  useEffect(
    (getProductsFunc = getProducts) => {
      getProductsFunc();
    },
    [params.listId]
  );

  const getProducts = async () => {
    if (params.listId) {
      getProductsListsByList(params.listId)
        .then((productsRes) => {
          if (productsRes.data.length > 0) {
            setListName(productsRes.data[0].list.name);
          }
          setProducts(productsRes.data);
        })
        .catch((err) => {
          setUnauthorized(true);
        });
    }
  };

  const editProductsLists = (data) => {
    let _products = products.map((product) => {
      if (product.idProductsLists === data.idProductsLists) {
        product[data.option] = data[data.option];
      }
      return product;
    });
    setProducts(_products);
  };

  const editProduct = (data) => {
    updateProduct(data)
      .then((updatedProduct) => {
        console.log(updatedProduct);
      })
      .catch((err) => {
        console.error(err);
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Hubo un error al editar el producto",
          sticky: true,
        });
      });
  };

  const imageBodyTemplate = (rowData) => {
    return (
      <Avatar
        image={rowData.image ? rowData.image : `/img/products_64.png`}
        className="mr-2"
        size="large"
        shape="circle"
        alt={rowData.image}
      />
    );
  };

  const quantityTemplate = (rowData) => {
    return (
      <InputNumber
        className="products-quantity"
        inputId="horizontal"
        value={rowData.quantity}
        onValueChange={(e) => {
          editProductsLists({
            idProductsLists: rowData.idProductsLists,
            option: "quantity",
            quantity: e.value,
          });
        }}
        showButtons
        step={1}
        min={0}
        max={30}
      />
    );
  };

  const expirationTemplate = (rowData) => {
    return (
      <Calendar
        id="icon"
        inputStyle={{ width: "150px" }}
        value={rowData.expiration}
        onChange={(e) => {
          e.preventDefault();
          editProductsLists({
            idProductsLists: rowData.idProductsLists,
            option: "expiration",
            expiration: e.value,
          });
        }}
        showIcon
        minDate={currentDate}
        showButtonBar
        dateFormat="dd/mm/yy"
        locale="es"
        monthNavigator
        yearNavigator
        monthNavigatorTemplate={monthNavigatorTemplate}
        yearNavigatorTemplate={yearNavigatorTemplate}
        yearRange={`${currentDate.getFullYear()}:${
          currentDate.getFullYear() + 10
        }`}
      />
    );
  };

  const monthNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        style={{ lineHeight: 1 }}
      />
    );
  };

  const yearNavigatorTemplate = (e) => {
    return (
      <Dropdown
        value={e.value}
        options={e.options}
        onChange={(event) => e.onChange(event.originalEvent, event.value)}
        className="ml-2"
        style={{ lineHeight: 1 }}
      />
    );
  };

  const priceTemplate = (rowData) => {
    return (
      <InputNumber
        inputStyle={{ width: "150px" }}
        inputId="integeronly"
        value={rowData.product.price}
        onBlur={(e) => {
          if (Number(rowData.product.price) !== Number(e.target.value)) {
            editProduct({
              idProduct: rowData.product.idProduct,
              price: e.target.value,
            });
          }
        }}
        mode="decimal"
        useGrouping={false}
      />
    );
  };

  const header = (
    <div className="table-header d-flex align-items-center justify-content-space-between">
      <div>{`Hay ${
        products ? products.length : 0
      } productos en la lista.`}</div>
    </div>
  );

  const savePurchaseRecord = () => {
    if (itemsChecked.length && selectedPantry) {
      const list = itemsChecked[0].list;
      const data = itemsChecked.map((item) => {
        return {
          idProduct: item.idProduct,
          product: item.product,
          quantity: item.quantity,
        };
      });
      createPurchaseRecord({
        list: list,
        listId: params.listId,
        products: data,
      })
        .then((newProductRes) => {
          setPurchaseCompleted(true);
          bulkSaveProductsPantries();
          toast.current.show({
            severity: "success",
            summary: "Compra Finalizada",
            detail: `La compra quedó registrada`,
            sticky: true,
          });
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Hubo un error al guardar el producto",
            sticky: true,
          });
        });
    } else {
      if (!selectedPantry) {
        setSelectedPantryState(true);
      }
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `No se ha seleccionado ningun producto ${
          !selectedPantry ? "o no se eligió despensa" : ""
        }`,
        sticky: true,
      });
    }
  };

  const bulkSaveProductsPantries = () => {
    if (itemsChecked.length && selectedPantry) {
      const data = itemsChecked.map((item) => {
        return {
          pantryId: selectedPantry,
          idProduct: item.idProduct,
          quantity: item.quantity,
          expiration: item.expiration || null,
        };
      });
      bulkCreateProductsPantries(data)
        .then((newProductRes) => {
          window.localStorage.removeItem("shopping-state");
          toast.current.show({
            severity: "success",
            summary: "Productos agregados",
            detail: `Los productos se añadieron a la despensa satisfactoriamente`,
            sticky: true,
          });
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Hubo un error al guardar el producto",
            sticky: true,
          });
        });
    } else {
      if (!selectedPantry) {
        setSelectedPantryState(true);
      }
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `No se ha seleccionado ningun producto ${
          !selectedPantry ? "o no se eligió despensa" : ""
        }`,
        sticky: true,
      });
    }
  };

  return (
    <>
      <div className="grid py-2">
        <div className="col-12 px-3">
          {!unauthorized && (
            <Card
              title={`Compras Lista ${listName}`}
              footer={
                <span className="grid justify-content-end">
                  <div className="col-12 sm:col-12 md:col-12 lg:col-6 xl:col-6">
                    <div className="d-flex align-items-center">
                      <label htmlFor="pantryId">
                        Despensa a donde ira la compra
                      </label>
                      <Dropdown
                        name="pantryId"
                        value={selectedPantry}
                        options={pantriesItems}
                        onChange={(e) => {
                          setSelectedPantryState(false);
                          setSelectedPantry(e.value);
                        }}
                        optionLabel="pantry.name"
                        optionValue="pantryId"
                        placeholder="Selecciona"
                        className={
                          selectedPantryState ? "p-invalid mx-3" : "mx-3"
                        }
                      />
                    </div>
                  </div>
                  <div className="col-12 sm:col-12 md:col-12 lg:col-3 xl:col-3">
                    <Button
                      label={
                        purchaseCompleted
                          ? "Compra Finalizada"
                          : "Terminar compra"
                      }
                      onClick={() => savePurchaseRecord()}
                      disabled={purchaseCompleted || products.length === 0}
                    />
                  </div>
                </span>
              }
            >
              <DataTable
                value={products}
                selection={itemsChecked}
                selectionMode="checkbox"
                onSelectionChange={(e) => {
                  var tempArr = products.filter((product) => {
                    return !e.value.find(
                      (item) => item.idProductsLists === product.idProductsLists
                    );
                  });
                  setProducts([...tempArr, ...e.value]);
                  setItemsChecked(e.value);
                }}
                header={header}
                responsiveLayout="stack"
                stripedRows
                emptyMessage={"No se encontraron productos en la lista"}
                dataKey="idProductsLists"
                stateStorage="local"
                stateKey="shopping-state"
              >
                <Column selectionMode="multiple"></Column>
                <Column header="" body={imageBodyTemplate}></Column>
                <Column field="product.name" header="Productos"></Column>
                <Column
                  body={expirationTemplate}
                  header="Fecha de vencimiento"
                ></Column>
                <Column body={priceTemplate} header="Precio"></Column>
                <Column header="Cantidad" body={quantityTemplate}></Column>
              </DataTable>
            </Card>
          )}
          {unauthorized && (
            <Card title={`No Autorizado`}>
              Lo sentimos, actualmente no tiene permiso para acceder a esta
              lista
            </Card>
          )}
        </div>
      </div>
      <Toast ref={toast} />
    </>
  );
};
