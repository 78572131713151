import { Container, Row, Col, ListGroup, Tab } from "react-bootstrap";

export const Warehouses = () => {
  return (
    <Container>
      <h2>Almacenes</h2><hr />
      <Tab.Container id="warehouses-tabs" defaultActiveKey="#link1">
        <Row>
          <Col xs="2" md="2">
            <ListGroup>
              <ListGroup.Item action href="#link1">
                Principal
              </ListGroup.Item>
              <ListGroup.Item action href="#link2">
                Almacenamiento
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col xs="10" md="auto">
            <Tab.Content>
              <Tab.Pane eventKey="#link1">
                <p>Productos almacen principal</p>
              </Tab.Pane>
              <Tab.Pane eventKey="#link2">
                <p>Productos almacenamiento</p>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
}