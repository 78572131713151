import axios from 'axios';

export const getpantriesUsers = async ()=>{
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_URL_API}/pantries-users`)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const createNewPantry = async (data)=>{
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_URL_API}/pantries`,data)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const updatePantry = (data)=>{
  const {pantryId, ...params} = data;
  return new Promise((resolve, reject) => {
    axios.patch(`${process.env.REACT_APP_URL_API}/pantries/${pantryId}`,params)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const createPantriesUsers = async (data)=>{
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_URL_API}/pantries-users`,data)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const updatePantriesUsers = (data)=>{
  const {idPantryUser, ...params} = data;
  return new Promise((resolve, reject) => {
    axios.patch(`${process.env.REACT_APP_URL_API}/pantries-users/${idPantryUser}`,params)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const updatePantriesUsersByUser = (data)=>{
  const {idPantryUser, ...params} = data;
  return new Promise((resolve, reject) => {
    axios.patch(`${process.env.REACT_APP_URL_API}/pantries-users/user/${idPantryUser}`,params)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}

export const getListsUsersByPantryId = async (pantryId)=>{
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_URL_API}/pantries-users/pantry/${pantryId}`)
      .then(res=>resolve(res))
      .catch(err => reject(err));
  });
}