import { Navigate, Outlet } from "react-router-dom";
import { useUser } from "./useUser";

export const ProtectedRoute = ({
  redirectPath = '/login',
  children,
}) => {
  const {isLogged} = useUser();
  if (!isLogged) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};