import React, { useState } from "react";
import { Card } from "primereact/card";
import { ListBox } from "primereact/listbox";

export const UserLanguage = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const languages = [
    { name: "Español", code: "ES" },
    { name: "English", code: "EN" },
  ];
  return (
    <>
      <Card title="Lenguaje (Desarrollo)" className="mb-2">
        <ListBox
          value={selectedLanguage}
          options={languages}
          onChange={(e) => setSelectedLanguage(e.value)}
          optionLabel="name"
          style={{ width: "15rem" }}
        />
      </Card>
    </>
  );
};
